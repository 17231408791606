import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Styles from "./index.module.css";

export const BackButton = () => {
	let navigate = useNavigate();
	return (
		<>
			<Row>
				<Col style={{ marginBottom: "10px" }}>
					<Button variant="none" className={Styles.backButton} onClick={() => navigate(-1)}>
						&laquo; Volver
					</Button>
				</Col>
			</Row>
		</>
	);
};
