import { Button } from "react-bootstrap";
import Styles from "./index.module.css";

type Props = {
	text: string | JSX.Element;
	style?: any;
	disabled?: boolean;
	onClick?: any;
};

const BasicAppButton = (prop: Props) => {
	const { text, disabled, onClick, style } = prop;

	const onClickHandler = (e: any) => {
		if (onClick) {
			return onClick(e);
		}
	};

	return (
		<Button variant="flat" type="button" className={Styles.BasicAppButton} disabled={disabled} onClick={onClickHandler} style={style}>
			{text}
		</Button>
	);
};

export default BasicAppButton;
