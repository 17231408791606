import CheckBox from "../CheckBox";

type Props = any;

export const CheckBoxField = (props: Props) => {
	const {
		onChange,
		label,
		checked,
		disabled,
		isInvalid,
		field: { name, value, onChange: FieldOnChange, onFocus, onBlur }
	} = props;

	const changeHandler = (e: any) => {
		FieldOnChange(e);
		if (onChange) onChange(e);
	};

	return (
		<>
			<CheckBox
				checked={checked}
				disabled={disabled}
				isInvalid={isInvalid}
				label={label}
				name={name}
				onBlur={onBlur}
				onChange={changeHandler}
				onFocus={onFocus}
				style={{ marginLeft: "1px", marginRight: "10px" }}
				value={value}
			/>
		</>
	);
};

export default CheckBoxField;
