import * as React from "react";
import { connect } from "react-redux";
import { Nav, Navbar } from "react-bootstrap";
import styles from "./index.module.css";
import { GlobalNavButtonIcon } from "@fluentui/react-icons-mdl2";
import { useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";
import Auth from "../../../auth/Auth";

type Props = {
	header_title: string;
	displayNavBar: (display: boolean) => void;
};

const Header = (props: Props) => {
	const { header_title, displayNavBar } = props;
	const [displayNavbar, setDisplayNavbar] = React.useState<boolean>(true);
	const matchW = useMediaQuery("(max-width: 1000px)");
	const matchH = useMediaQuery("(max-height: 880px)");
	const responsive = matchW || matchH;

	async function signOutClickHandler() {
		await Auth.logout();
	}

	return (
		<header className={styles.AppHeader}>
			<Navbar expand="true">
				<Nav style={{ display: "-webkit-inline-box", marginTop: "auto", marginBottom: "auto" }}>
					{responsive && (
						<div
							style={{ cursor: "pointer" }}
							onClick={() => {
								setDisplayNavbar(!displayNavbar);
								displayNavBar(!displayNavbar);
							}}
						>
							<GlobalNavButtonIcon style={{ margin: "auto 20px auto 0px" }} />
						</div>
					)}
					<Navbar.Text>
						<h4 style={{ marginBottom: "0px" }}>{header_title}</h4>
					</Navbar.Text>
				</Nav>
				<Nav>
					<Navbar.Text
						className={styles.LogoutBtn}
						onClick={() => {
							signOutClickHandler();
						}}
					>
						<NavLink className={styles.navlink} to="#LogOut">
							Cerrar sesión
						</NavLink>
					</Navbar.Text>
				</Nav>
			</Navbar>
		</header>
	);
};

const mapStateProps = (state: any) => ({
	header_title: state.page.header_title
});

export default connect(mapStateProps)(Header);
