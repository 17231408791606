import React from "react";
import { SaveUserTest, TestQuestion } from "../../../services";
import MapSingleSelect from "../MapSingleSelect";
import MapTopicText from "../MapTopicText";
import MapMultiSelect from "../MapMultiSelect";
import Styles from "./index.module.css";
import MapTable from "../MapTable";
import MapTableRow from "../MapTableRow";
import MapTableColumn from "../MapTableColumn";
import { FormikErrors } from "formik";
import MapTopicTitle from "../MapTopicTitle";
import MapImageUrl from "../MapImageUrl";
import MapPercentageOptions from "../MapPercentageOptions";

type Props = {
	question: TestQuestion;
	index: number;
	values: SaveUserTest;
	errors: FormikErrors<SaveUserTest>;
	validateForm: boolean;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const MapQuestionTypes = (props: Props) => {
	const { question, index, setFieldValue, values, errors, validateForm } = props;

	return (
		<React.Fragment key={index + "-" + question.id}>
			{question.type === 1 /*       TopicTextTitle       */ && (
				<div className={Styles.QuestionRowLevel}>
					<MapTopicTitle
						question={question}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
						validateForm={validateForm}
					></MapTopicTitle>
				</div>
			)}
			{question.type === 2 /*   SingleOption   */ && (
				<div className={Styles.QuestionRowLevel}>
					<MapSingleSelect
						question={question}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
						validateForm={validateForm}
					></MapSingleSelect>
				</div>
			)}
			{question.type === 3 /*   MultiChoice   */ && (
				<div className={Styles.QuestionRowLevel}>
					<MapMultiSelect
						question={question}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
						validateForm={validateForm}
					></MapMultiSelect>
				</div>
			)}
			{question.type === 4 /*   Table   */ && (
				<MapTable question={question} setFieldValue={setFieldValue} values={values} errors={errors} validateForm={validateForm}></MapTable>
			)}
			{question.type === 5 /*   TableRow   */ && (
				<MapTableRow
					question={question}
					index={index}
					setFieldValue={setFieldValue}
					values={values}
					errors={errors}
					validateForm={validateForm}
				></MapTableRow>
			)}
			{question.type === 6 /*   TableColumn   */ && (
				<MapTableColumn question={question} setFieldValue={setFieldValue} values={values} errors={errors} validateForm={validateForm}></MapTableColumn>
			)}
			{question.type === 7 /*       TopicText       */ && (
				<div className={Styles.QuestionRowLevel}>
					<MapTopicText question={question} setFieldValue={setFieldValue} values={values} errors={errors} validateForm={validateForm}></MapTopicText>
				</div>
			)}
			{question.type === 8 /*       ImageUrl       */ && (
				<div className={Styles.QuestionRowLevel}>
					<MapImageUrl question={question} setFieldValue={setFieldValue} values={values} errors={errors} validateForm={validateForm}></MapImageUrl>
				</div>
			)}
			{question.type === 9 /*   PercentageOptions   */ && (
				<div className={Styles.QuestionRowLevel}>
					<MapPercentageOptions
						question={question}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
						validateForm={validateForm}
					></MapPercentageOptions>
				</div>
			)}
		</React.Fragment>
	);
};

export default MapQuestionTypes;
