import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import config from "../config";

let reactPlugin = new ReactPlugin();
let appInsights = null;

if (process.env.NODE_ENV === "production") {
	reactPlugin = new ReactPlugin();
	appInsights = new ApplicationInsights({
		config: {
			connectionString: config.APPLICATIONINSIGHTS_CONNECTION_STRING,
			//@ts-ignore
			extensions: [reactPlugin],
			extensionConfig: {},
			enableAutoRouteTracking: true,
			disableAjaxTracking: false,
			autoTrackPageVisitTime: true,
			enableCorsCorrelation: true,
			enableRequestHeaderTracking: true,
			enableResponseHeaderTracking: true
		}
	});
	appInsights.loadAppInsights();

	appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
		env.tags = env.tags || [];
		env.tags["ai.cloud.role"] = "testTag";
	});
}

export { reactPlugin, appInsights };
