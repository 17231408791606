import fetchResource from "./fetch";
import config from "../config";

export type ClientContracts = {
	id: number;
	fullName: string;
	idDocument: string;
	contracts: Contract[];
};

export type Contract = {
	id: number;
	code: string;
	contractTypeId: number;
	contractType: string;
	relatedProductTypes: number[];
	cc: string;
	cv: string;
	totalPayOutAmount: number;
	totalCommitedAmount: number;
	totalLiquidity: number;
	totalPendingAmount: number;
	targetPortfolio: number;
	committedTotalOverTarget: number;
	payOutTotalOverTarget: number;
	contractedProducts: ContractedProduct[];
	userContractType: string;
	userContractTypeId: number;
	contractOwnerIdDoc?: string;
	contractOwnerName?: string;
};

export type ContractedProduct = {
	id: number;
	productName: string;
	productId: number;
	payOut: number;
	committed: number;
	liquidity: number;
	pendingCapital: number;
	shares: number;
};

export const getClientsContracts = async (idDocument: string) => {
	const url = `${config.proxy}/contracts/client/${encodeURIComponent(idDocument)}`;
	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": `${config.API_CONTRACTS_CLIENT_CODE}`
		}
	};

	return fetchResource(url, request);
};
