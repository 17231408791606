type Configuration = {
	proxy_url?: string;
	server_host?: string;
	powerbi_service_url?: string;
	powerbi_api_reporttokens_code?: string;
	API_CONTRACTS_URL?: string;
	API_CONTRACTS_CLIENT_CODE?: string;
	API_ORDERS_URL?: string;
	API_ORDERS_ORDERSBYCLIENT_CODE?: string;
	API_ORDERS_BYPRODUCTCONTRACT_CODE?: string;
	API_CLIENTS_URL?: string;
	API_CLIENTS_ALLOWEDLOGINCUSTOMERS_CODE?: string;
	APPLICATIONINSIGHTS_CONNECTION_STRING?: string;
	API_TESTS_URL?: string;
	API_TESTS_GETTESTVERSION_CODE?: string;
	API_TESTS_GETUSERTESTS_CODE?: string;
	API_TESTS_SAVEUSERTEST_CODE?: string;
	API_TESTS_GETTESTBYID_CODE?: string;
    proxy?: string;
};

var config: Configuration = {
	proxy_url: "",
	server_host: "",
	powerbi_service_url: "",
	powerbi_api_reporttokens_code: "",
	API_CONTRACTS_URL: "",
	API_CONTRACTS_CLIENT_CODE: "",
	API_ORDERS_URL: "",
	API_ORDERS_ORDERSBYCLIENT_CODE: "",
	API_ORDERS_BYPRODUCTCONTRACT_CODE: "",
	API_CLIENTS_URL: "",
	API_CLIENTS_ALLOWEDLOGINCUSTOMERS_CODE: "",
	APPLICATIONINSIGHTS_CONNECTION_STRING: "",
	API_TESTS_URL: "",
	API_TESTS_GETTESTVERSION_CODE: "",
	API_TESTS_GETUSERTESTS_CODE: "",
	API_TESTS_SAVEUSERTEST_CODE: "",
	API_TESTS_GETTESTBYID_CODE: "",
    proxy: ""
};

switch (process.env.NODE_ENV) {
	case "production": {
		config = {
			proxy_url: "#{PROXY_URL}#",
			server_host: "#{SERVER_HOST}#",
			powerbi_service_url: "#{POWERBI_SERVICE_URL}#",
			powerbi_api_reporttokens_code: "#{POWERBI_API_REPORTTOKENS_CODE}#",
			API_CONTRACTS_URL: "#{API_CONTRACTS_URL}#",
			API_CONTRACTS_CLIENT_CODE: "#{API_CONTRACTS_CLIENT_CODE}#",
			API_ORDERS_URL: "#{API_ORDERS_URL}#",
			API_ORDERS_ORDERSBYCLIENT_CODE: "#{API_ORDERS_ORDERSBYCLIENT_CODE}#",
			API_ORDERS_BYPRODUCTCONTRACT_CODE: "#{API_ORDERS_BYPRODUCTCONTRACT_CODE}#",
			API_CLIENTS_URL: "#{API_CLIENTS_URL}#",
			API_CLIENTS_ALLOWEDLOGINCUSTOMERS_CODE: "#{API_CLIENTS_ALLOWEDLOGINCUSTOMERS_CODE}#",
			APPLICATIONINSIGHTS_CONNECTION_STRING: "#{APPLICATIONINSIGHTS_CONNECTION_STRING}#",
			API_TESTS_URL: "#{API_TESTS_URL}#",
			API_TESTS_GETTESTVERSION_CODE: "#{API_TESTS_GETTESTVERSION_CODE}#",
			API_TESTS_GETUSERTESTS_CODE: "#{API_TESTS_GETUSERTESTS_CODE}#",
			API_TESTS_SAVEUSERTEST_CODE: "#{API_TESTS_SAVEUSERTEST_CODE}#",
			API_TESTS_GETTESTBYID_CODE: "#{API_TESTS_GETTESTBYID_CODE}#",
            proxy:"#{proxy}#",
		};
		break;
	}
	default: {
		config = {
			API_CLIENTS_ALLOWEDLOGINCUSTOMERS_CODE: process.env.REACT_APP_API_CLIENTS_ALLOWEDLOGINCUSTOMERS_CODE,
			API_CLIENTS_URL: process.env.REACT_APP_API_CLIENTS_URL,
			API_CONTRACTS_URL: process.env.REACT_APP_API_CONTRACTS_URL,
			API_ORDERS_ORDERSBYCLIENT_CODE: process.env.REACT_APP_API_ORDERS_ORDERSBYCLIENT_CODE,
			API_ORDERS_URL: process.env.REACT_APP_API_ORDERS_URL,
			API_TESTS_GETTESTBYID_CODE: process.env.REACT_APP_API_TESTS_GETTESTBYID_CODE,
			API_TESTS_GETUSERTESTS_CODE: process.env.REACT_APP_API_TESTS_GETUSERTESTS_CODE,
			API_TESTS_URL: process.env.REACT_APP_API_TESTS_URL,
			APPLICATIONINSIGHTS_CONNECTION_STRING: process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
			powerbi_api_reporttokens_code: process.env.powerbi_api_reporttokens_code,
			powerbi_service_url: process.env.REACT_APP_POWERBI_SERVICE_URL,
			proxy_url: process.env.REACT_APP_PROXY_URL,
			server_host: process.env.REACT_APP_SERVER_HOST,
            proxy: process.env.REACT_APP_PROXY,
		};
		break;
	}
}

export default config;
