import React from "react";
import { SaveUserTest, TestQuestion } from "../../../services";
import { Col, Row } from "react-bootstrap";
import Styles from "./index.module.css";
import { Field, FormikErrors } from "formik";
import MapQuestionTypes from "../MapQuestionTypes";
import CheckBoxField from "../../Form/CheckBoxField";

type Props = {
	question: TestQuestion;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	values: SaveUserTest;
	errors: FormikErrors<SaveUserTest>;
	validateForm: boolean;
};

const MapMultiSelect = (props: Props) => {
	const { question, setFieldValue, values, errors, validateForm } = props;
	const [displayQuestion, setDisplayQuestion] = React.useState<boolean>(false);

	const requiredQuestionStyle =
		validateForm && !question.isNotRequired && !values.scoreQuestions.some(sq => sq.questionId === question.id)
			? Styles.QuestionLabelInValid
			: Styles.QuestionLabel;

	React.useEffect(() => {
		if (question.showOnAnswerId) {
			setDisplayQuestion(values.scoreQuestions.some(sq => sq.answers.some(x => x.answerId === question.showOnAnswerId) === true));
		} else {
			setDisplayQuestion(true);
		}
	}, [question.showOnAnswerId, values]);

	return (
		<>
			{displayQuestion && (
				<>
					<Col lg={12}>
						<Row className={Styles.QuestionRow}>
							<div className={requiredQuestionStyle}>
								{question.text}
								{!question.text &&
									validateForm &&
									!question.isNotRequired &&
									!values.scoreQuestions.some(sq => sq.questionId === question.id) && <i>requerido</i>}
							</div>
							{question.answers.map((answer, index) => (
								<Field
									id={question.id + "-" + answer.id}
									name={answer.id.toString()}
									type="checkbox"
									key={index}
									label={answer.text}
									component={CheckBoxField}
									checked={answer.checked}
									value={index.toString()}
									onChange={(e: any) => {
										const { checked } = e.target;
										const selectedValues = values.scoreQuestions || [];
										const index = selectedValues.findIndex(item => item.questionId === question.id);

										if (checked) {
											// If checked and not found, add it
											if (index === -1) {
												selectedValues.push({
													questionId: question.id,
													integrationCode: question.integrationCode ?? "",
													questionScore: answer.scoreValue,
													answers: [{ answerId: answer.id, value: checked.toString() }]
												});
											} else {
												// If checked and found, update the score value by adding answer.scoreValue
												selectedValues[index].questionScore += answer.scoreValue;
												const selectedAnswers = selectedValues[index].answers;
												const foundAnswer = selectedAnswers.find(x => x.answerId === answer.id);
												if (!foundAnswer) {
													selectedValues[index].answers.push({ answerId: answer.id, value: checked.toString() });
												} else {
													foundAnswer.value = checked.toString();
												}
											}
										} else {
											// If unchecked and found, update the score value by subtracting answer.scoreValue
											if (index !== -1) {
												selectedValues[index].questionScore -= answer.scoreValue;
												const foundAnswer = selectedValues[index].answers.find(x => x.answerId === answer.id);
												if (foundAnswer) {
													const answerIndexToRemove = selectedValues[index].answers.indexOf(foundAnswer);
													if (answerIndexToRemove !== -1) {
														selectedValues[index].answers.splice(answerIndexToRemove, 1);
													}
												}
											}

											// Check if there are no remaining selectedAnswersIds
											if (selectedValues[index].answers.length === 0) {
												// Remove the item from selectedValues array
												selectedValues.splice(index, 1);
											}
										}

										setFieldValue("scoreQuestions", selectedValues);
									}}
								></Field>
							))}
						</Row>
					</Col>
					{question?.subquestions &&
						question?.subquestions.map((subQuestion, index) => (
							<MapQuestionTypes
								question={subQuestion}
								index={index}
								setFieldValue={setFieldValue}
								values={values}
								errors={errors}
								validateForm={validateForm}
							></MapQuestionTypes>
						))}
				</>
			)}
		</>
	);
};
export default MapMultiSelect;
