import React from "react";
import Loading from "../../../components/Loading";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { pageActions as actions } from "../../../store/page";
import { Formik, Form as FormFormik, FormikHelpers } from "formik";
import { SaveUserTest, ScoreQuestions, Test, TestQuestion, saveUserTest } from "../../../services";
import * as Yup from "yup";
import MapQuestionTypes from "../../../components/Tests/MapQuestionTypes";
import Styles from "./index.module.css";

type Props = {
	test: Test;
	updatePageHeaderTitle: (title: string) => void;
};

const CreateTestById = (props: Props) => {
	const { test } = props;
	const [loading, setLoading] = React.useState<boolean>(false);
	const [validTest, setValidTest] = React.useState<boolean | undefined>();

	const submitHandler = async (values: SaveUserTest, actions: FormikHelpers<SaveUserTest>) => {
		setLoading(false);
	};

	const initialValues: SaveUserTest = {
		scoreQuestions: []
	};

	return (
		<>
			{loading && <Loading type="page" />}
			{!loading && (
				<Formik initialValues={initialValues} onSubmit={submitHandler}>
					{({ values, isSubmitting, setFieldValue, touched, errors }) => (
						<FormFormik>
							{/* DS Help Tools */}
							{/* {JSON.stringify(values.scoreQuestions, null, 2)} */}
							{/* {JSON.stringify(errors)} */}
							<div className={Styles.QuestionStartingRowLevel}>
								{test &&
									test.testIncludedQuestions &&
									test.testIncludedQuestions.map((includedQuestion, index) => (
										<MapQuestionTypes
											key={index}
											question={includedQuestion.question}
											index={index}
											values={values}
											setFieldValue={setFieldValue}
											errors={errors}
											validateForm={validTest === false}
										></MapQuestionTypes>
									))}
							</div>
						</FormFormik>
					)}
				</Formik>
			)}
		</>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(CreateTestById);
