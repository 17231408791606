// Helpers
import { dispatch } from "../../helpers/actions";

const USER_IS_AUTHENTICATED = "USER_IS_AUTHENTICATED";
const USER_IS_FID_CUSTOMER = "USER_IS_FID_CUSTOMER";
const USER_ID = "USER_ID";
const USER_DOC_ID = "USER_DOC_ID";
const USER_TYPE_PERSON_ID = "USER_TYPE_PERSON_ID";
const USER_RELATED_OWNER_CONTRACT_DOC_ID = "USER_RELATED_OWNER_CONTRACT_DOC_ID";

export const authActions = {
	USER_IS_AUTHENTICATED,
	USER_IS_FID_CUSTOMER,
	USER_ID,
	USER_DOC_ID,
	USER_TYPE_PERSON_ID,
	USER_RELATED_OWNER_CONTRACT_DOC_ID,
	userIsAuthenticated: (payload: boolean) => dispatch(USER_IS_AUTHENTICATED, payload),
	userIsFidCustomer: (payload: boolean) => dispatch(USER_IS_FID_CUSTOMER, payload),
	setUserId: (payload: number) => dispatch(USER_ID, payload),
	setUserDocId: (payload: string) => dispatch(USER_DOC_ID, payload),
	setUserTypePersonId: (payload: number) => dispatch(USER_TYPE_PERSON_ID, payload),
	setUserRelatedOwnerContractDocId: (payload: string | undefined) => dispatch(USER_RELATED_OWNER_CONTRACT_DOC_ID, payload)
};
