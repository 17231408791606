import React from "react";
import { SaveUserTest, ScoreQuestions, TestQuestion } from "../../../services";
import { Col, Form, Row } from "react-bootstrap";
import Styles from "./index.module.css";
import { FormikErrors } from "formik";
import MapQuestionTypes from "../MapQuestionTypes";

type Props = {
	question: TestQuestion;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	values: SaveUserTest;
	errors: FormikErrors<SaveUserTest>;
	validateForm: boolean;
};

const MapPercentageOptions = (props: Props) => {
	const { question, setFieldValue, values, errors, validateForm } = props;
	const [displayQuestion, setDisplayQuestion] = React.useState<boolean>(false);
	const checkValidTotal = (scoreQuestion: ScoreQuestions | undefined) => {
		let total = 0;

		if (!scoreQuestion) {
			return false;
		}

		for (const item of scoreQuestion.answers) {
			total += parseFloat(item.value);
		}

		return total === 100;
	};

	const requiredQuestionStyle =
		validateForm && !question.isNotRequired && !checkValidTotal(values.scoreQuestions.find(x => x.questionId === question.id))
			? Styles.QuestionLabelInValid
			: Styles.QuestionLabel;

	React.useEffect(() => {
		if (question.showOnAnswerId) {
			setDisplayQuestion(values.scoreQuestions.some(sq => sq.answers.some(x => x.answerId === question.showOnAnswerId) === true));
		} else {
			setDisplayQuestion(true);
		}
	}, [question.showOnAnswerId, values]);

	return (
		<>
			{displayQuestion && (
				<>
					<Col lg={12}>
						<Row className={Styles.QuestionRow}>
							<div className={requiredQuestionStyle}>
								{question.text}
								{!question.text &&
									validateForm &&
									!question.isNotRequired &&
									!checkValidTotal(values.scoreQuestions.find(x => x.questionId === question.id)) && <i>requerido</i>}
							</div>
							{question.answers.map((answer, index) => (
								<div key={index} className={Styles.inputContainer}>
									<Form.Control
										id={question.id + "-" + answer.id}
										name={answer.id.toString()}
										type="number"
										max={100}
										min={0}
										className={Styles.percentageInput}
										value={
											values.scoreQuestions?.find(x => x.questionId === question.id)?.answers?.find(x => x.answerId === answer.id)
												?.value ?? answer.textValue
										}
										onChange={(e: any) => {
											const value = e.target.value;
											const selectedValues = values.scoreQuestions || [];
											const index = selectedValues.findIndex(item => item.questionId === question.id);

											//not found, add it
											if (index === -1) {
												selectedValues.push({
													questionId: question.id,
													integrationCode: question.integrationCode ?? "",
													questionScore: answer.scoreValue * (value / 100),
													answers: [{ answerId: answer.id, value: value.toString() }]
												});
											} else {
												// found, add the answer
												const selectedAnswers = selectedValues[index].answers;
												const thisAnswerIndex = selectedAnswers.findIndex(x => x.answerId === answer.id);
												if (thisAnswerIndex === -1) {
													selectedValues[index].answers.push({ answerId: answer.id, value: value.toString() });
												} else {
													selectedValues[index].answers[thisAnswerIndex].value = value.toString();
												}

												selectedValues[index].questionScore = 0;
												// update the score value
												for (let i = 0; i < selectedValues[index]?.answers?.length; i++) {
													selectedValues[index].questionScore +=
														answer.scoreValue * (parseFloat(selectedValues[index]?.answers[i].value) / 100);
												}
											}

											setFieldValue("scoreQuestions", selectedValues);
										}}
									></Form.Control>
									<label htmlFor={question.id + "-" + answer.id} className={Styles.inputlabel}>
										{answer.text}
									</label>
								</div>
							))}
						</Row>
					</Col>
					{question?.subquestions &&
						question?.subquestions.map((subQuestion, index) => (
							<MapQuestionTypes
								question={subQuestion}
								index={index}
								setFieldValue={setFieldValue}
								values={values}
								errors={errors}
								validateForm={validateForm}
							></MapQuestionTypes>
						))}
				</>
			)}
		</>
	);
};

export default MapPercentageOptions;
