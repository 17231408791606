import { Link, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

// Styles
import styles from "./index.module.css";
import logo from "../../../assets/images/diaphanum.png";
import logoMini from "../../../assets/images/logoMini.png";
import { IoIosPeople, IoMdClipboard } from "react-icons/io";
import { FiSettings } from "react-icons/fi";
import { FormattedMessage } from "react-intl";

type Props = {
	displayNavbar: boolean;
};

function SideBar(props: Props) {
	const matchW = useMediaQuery("(max-width: 1000px)");
	const matchH = useMediaQuery("(max-height: 880px)");
	const heightToSmall = useMediaQuery("(max-height: 524px)");
	const responsive = matchW || matchH;
	let iconSize = responsive ? 30 : 40;
	iconSize = heightToSmall ? 20 : iconSize;
	const { displayNavbar } = props;

	return (
		<div className={styles.SideBar} style={{ display: displayNavbar ? "inline" : "none" }}>
			<div className={styles.SideBarContent}>
				{!heightToSmall && (
					<Link to="/">
						<img src={responsive ? logoMini : logo} className={responsive ? styles.LogoMini : styles.DiaphanumLogo} alt="logo" />
					</Link>
				)}
				<Nav className={styles.LinkList}>
					<div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/client">
							<IoIosPeople size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.clients" />
							</div>
						</NavLink>
					</div>
					 <div className={styles.LinkContainer}>
						{/* <NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/tests">
							<IoMdClipboard size={iconSize} />
							<br />
							<div className={styles.LinkName}>Cuestionario MiFID</div>
						</NavLink> */}
					</div> 
					<div className={styles.LinkContainer}>
						<NavLink className={navData => (navData.isActive ? "ActiveSideBarLink" : "SideBarLink")} to="/settings">
							<FiSettings size={iconSize} />
							<br />
							<div className={styles.LinkName}>
								<FormattedMessage id="sidebar.configuration" />
							</div>
						</NavLink>
					</div>
				</Nav>
			</div>
		</div>
	);
}

export default SideBar;
