// Actions
import { authActions as actions } from "./actions";

// Types
import { AnyAction } from "redux";

export type UserStateType = {
	isAuthenticated: boolean;
	isFidCustomer: boolean;
	id: number | undefined;
	docId: string | undefined;
	typePersonId: number | undefined;
	relatedOwnerContractIdDoc: string | undefined;
};

// Default state
const defaultState: UserStateType = {
	isAuthenticated: false,
	isFidCustomer: false,
	id: undefined,
	docId: undefined,
	typePersonId: undefined,
	relatedOwnerContractIdDoc: undefined
};

export const reducer = (state = defaultState, action: AnyAction) => {
	const { payload } = action;

	switch (action.type) {
		case actions.USER_IS_AUTHENTICATED:
			return { ...state, isAuthenticated: payload };
		case actions.USER_IS_FID_CUSTOMER:
			return { ...state, isFidCustomer: payload };
		case actions.USER_ID:
			return { ...state, id: payload };
		case actions.USER_DOC_ID:
			return { ...state, docId: payload };
		case actions.USER_TYPE_PERSON_ID:
			return { ...state, typePersonId: payload };
		case actions.USER_RELATED_OWNER_CONTRACT_DOC_ID:
			return { ...state, relatedOwnerContractIdDoc: payload };
		default:
			return state;
	}
};
