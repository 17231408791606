import * as React from "react";
import { connect } from "react-redux";
import Auth from "../auth/Auth";
import { Dispatch } from "redux";
import { authActions as actions } from "../store/auth";
import Loading from "../components/Loading";
import { getAllowedLoginCustomers } from "../services";
import { useNavigate } from "react-router-dom";

type Props = {
	element: any;
	isAuthenticated: boolean;
	isFidCustomer: boolean;
	id: number;
	userIsAuthenticated: (isAuthenticated: boolean) => void;
	userIsFidCustomer: (isPartner: boolean) => void;
	setUserId: (id: number) => void;
	setUserDocId: (docId: string) => void;
	setUserTypePersonId: (id: number) => void;
};

const AuthenticatedRoute = (props: Props) => {
	const { element, isAuthenticated, isFidCustomer, id, userIsAuthenticated, userIsFidCustomer, setUserId, setUserDocId, setUserTypePersonId } = props;
	const [isUserLoaded, setIsUserLoaded] = React.useState(false);
	const navigate = useNavigate();

	const checkUser = React.useCallback(async () => {
		let userAuthenticated = await Auth.userLogin();
		userIsAuthenticated(userAuthenticated);
		if (!userAuthenticated) {
			await Auth.logout();
		} else {
			let email = Auth.getUserEmail();
			if (!email || email.trim() === "") {
				await Auth.logout();
			}
			if (email) {
				let fidCustomerResponse = await getAllowedLoginCustomers(email);
				if (!fidCustomerResponse) {
					navigate("/access-denied");
				} else if (fidCustomerResponse!.accountIssue) {
					navigate("/account-error");
				} else if (!fidCustomerResponse!.allowed) {
					navigate("/access-denied");
				}
				setUserId(fidCustomerResponse!.customerId);
				setUserDocId(fidCustomerResponse!.customerDocumentId);
				userIsFidCustomer(fidCustomerResponse!.allowed);
				setUserTypePersonId(fidCustomerResponse!.typePersonId);
			}
		}
	}, [setUserDocId, setUserId, userIsAuthenticated, userIsFidCustomer, setUserTypePersonId, navigate]);

	React.useEffect(() => {
		if (!isUserLoaded) {
			checkUser().finally(() => {
				setIsUserLoaded(true);
			});
		}
	}, [checkUser, isUserLoaded]);

	React.useEffect(() => {
		const logout = async () => {
			await Auth.logout();
		};

		if (isUserLoaded && (isAuthenticated === false || isFidCustomer === false || !id)) {
			logout();
		}
	}, [isUserLoaded, isAuthenticated, isFidCustomer, id]);

	if (!isUserLoaded) {
		return <Loading type="page"></Loading>;
	}

	if (isUserLoaded && (isAuthenticated === false || isFidCustomer === false || !id)) return null;

	return element;
};

const mapStateToProps = (state: any) => ({
	isAuthenticated: state.auth.isAuthenticated,
	isFidCustomer: state.auth.isFidCustomer,
	id: state.auth.id
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	userIsAuthenticated: (isAuthenticated: boolean) => dispatch(actions.userIsAuthenticated(isAuthenticated)),
	userIsFidCustomer: (isFidCustomer: boolean) => dispatch(actions.userIsFidCustomer(isFidCustomer)),
	setUserId: (id: number) => dispatch(actions.setUserId(id)),
	setUserDocId: (docId: string) => dispatch(actions.setUserDocId(docId)),
	setUserTypePersonId: (id: number) => dispatch(actions.setUserTypePersonId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);
