import React from "react";
import "./App.css";
import Body from "./components/PageLayout/Body";
import Footer from "./components/PageLayout/Footer";
import Header from "./components/PageLayout/Header";
import CustomNavbar from "./components/PageLayout/SideBar";
import CustomRoutes from "./routes";

const App = () => {
	const [displayNavbar, setDisplayNavbar] = React.useState<boolean>(true);

	return (
		<div className="App">
			<CustomNavbar displayNavbar={displayNavbar} />
			<div className="AppContainer">
				<Header
					displayNavBar={display => {
						setDisplayNavbar(display);
					}}
				/>
				<Body>
					<CustomRoutes />
				</Body>
				<Footer />
			</div>
		</div>
	);
};

export default App;
