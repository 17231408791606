import fetchResource from "./fetch";
import config from "../config";

export type TestType = {
	id: number;
	name: string;
	displayName: string;
};

export type Test = {
	id: number;
	testTypeId: number;
	version: number;
	createDate: Date;
	description: string;
	testType: TestType;
	testIncludedQuestions: TestIncludedQuestion[];
};

export type TestIncludedQuestion = {
	id: number;
	questionId: number;
	testId: number;
	order: number;
	question: TestQuestion;
};

export type TestQuestion = {
	id: number;
	text: string;
	type: number;
	parentQuestionId: number | null;
	integrationCode?: string;
	answers: TestQuestionAnswer[];
	parentQuestion: TestQuestion | null;
	isNotRequired?: boolean;
	subquestions: TestQuestion[];
	testsThatIncludeTheQuestion: TestIncludedQuestion[];
	showOnAnswerId?: number;
};

export type TestQuestionAnswer = {
	id: number;
	questionId: number;
	text: string;
	scoreValue: number;
	checked: boolean;
	textValue: string;
};

export type UserTestsDto = {
	id: number;
	score: number;
	status: string;
	testId: number;
	createDate: Date;
	updateDate: Date;
	testType: string;
	testTypeId: number;
	scoreRank: string;
};

export type SaveUserTest = {
	testId?: number;
	scoreQuestions: ScoreQuestions[];

	//To control the imputs
	[key: string]: any;
};

export type ScoreQuestions = {
	questionId: number;
	integrationCode: string;
	questionScore: number;
	answers: ScoreAnswer[];
};

export type ScoreAnswer = {
	answerId: number;
	value: string;
};

export const getUserTests = async (idDoc: string): Promise<UserTestsDto[]> => {
	const url = `${config.proxy}/tests/client/${encodeURIComponent(idDoc)}`;
	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": `${config.API_TESTS_GETUSERTESTS_CODE}`
		}
	};

	return fetchResource(url, request);
};

export const getLatestTestVersion = async (typeId: number): Promise<Test> => {
	const url = `${config.proxy}/test/${typeId}`;
	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": `${config.API_TESTS_GETTESTVERSION_CODE}`
		}
	};

	return fetchResource(url, request);
};

export const saveUserTest = async (idDoc: string, saveUserTest: SaveUserTest): Promise<SaveUserTest> => {
	const url = `${config.proxy}/test/client/${encodeURIComponent(idDoc)}`;
	const request = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": `${config.API_TESTS_SAVEUSERTEST_CODE}`
		},
		body: JSON.stringify(saveUserTest)
	};

	return fetchResource(url, request);
};

export const getTestById = async (idTest: string) => {
	const url = `${config.proxy}/test/test-by-id/${encodeURIComponent(idTest)}`;
	const request = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"x-functions-key": `${config.API_TESTS_GETTESTBYID_CODE}`
		}
	};

	return fetchResource(url, request);
};
