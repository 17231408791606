import React from "react";
import { SaveUserTest, TestQuestion } from "../../../services";
import { Col, Row } from "react-bootstrap";
import Styles from "./index.module.css";
import { Field, FormikErrors } from "formik";
import RadioButtonField from "../../Form/RadioButtonField";
import MapQuestionTypes from "../MapQuestionTypes";

type Props = {
	question: TestQuestion;
	values: SaveUserTest;
	errors: FormikErrors<SaveUserTest>;
	validateForm: boolean;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const MapSingleSelect = (props: Props) => {
	const { question, setFieldValue, values, errors, validateForm } = props;
	const [displayQuestion, setDisplayQuestion] = React.useState<boolean>(false);
	React.useEffect(() => {
		if (question.showOnAnswerId) {
			setDisplayQuestion(values.scoreQuestions.some(sq => sq.answers.some(x => x.answerId === question.showOnAnswerId) === true));
		} else {
			setDisplayQuestion(true);
		}
	}, [question.showOnAnswerId, values]);

	const requiredQuestionStyle =
		validateForm && !question.isNotRequired && !values.scoreQuestions.some(sq => sq.questionId === question.id)
			? Styles.QuestionLabelInValid
			: Styles.QuestionLabel;

	return (
		<>
			{displayQuestion && (
				<>
					<Col lg={12}>
						<Row className={Styles.QuestionRow}>
							<div className={requiredQuestionStyle}>
								{question.text}
								{!question.text &&
									validateForm &&
									!question.isNotRequired &&
									!values.scoreQuestions.some(sq => sq.questionId === question.id) && <i>requerido</i>}
							</div>
							{question.answers.map((answer, index) => (
								<Field
									id={question.id + "-" + answer.id}
									name={question.id.toString()}
									type="radio"
									key={index}
									checked={answer.checked}
									component={RadioButtonField}
									value={index.toString()}
									label={answer.text}
									onChange={(e: any) => {
										const selectedValues = values.scoreQuestions || [];
										const index = selectedValues.findIndex(item => item.questionId === question.id);

										// If found, update it
										if (index !== -1) {
											selectedValues[index].answers = [{ answerId: answer.id, value: "checked" }];
											selectedValues[index].questionScore = answer.scoreValue;
										} else {
											//else add it
											selectedValues.push({
												questionId: question.id,
												integrationCode: question.integrationCode ?? "",
												questionScore: answer.scoreValue,
												answers: [{ answerId: answer.id, value: "checked" }]
											});
										}

										setFieldValue("scoreQuestions", selectedValues);
									}}
								></Field>
							))}
						</Row>
					</Col>
					{question?.subquestions &&
						question?.subquestions.map((subQuestion, index) => (
							<MapQuestionTypes
								question={subQuestion}
								index={index}
								setFieldValue={setFieldValue}
								values={values}
								errors={errors}
								validateForm={validateForm}
							></MapQuestionTypes>
						))}
				</>
			)}
		</>
	);
};

export default MapSingleSelect;
