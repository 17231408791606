import React from "react";
import { SaveUserTest, TestQuestion } from "../../../services";
import Styles from "./index.module.css";
import MapQuestionTypes from "../MapQuestionTypes";
import { FormikErrors } from "formik";

type Props = {
	question: TestQuestion;
	values: SaveUserTest;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	index: number;
	errors: FormikErrors<SaveUserTest>;
	validateForm: boolean;
};

const MapTableRow = (props: Props) => {
	const { question, setFieldValue, values, index, errors, validateForm } = props;

	return (
		<tr key={index} className={index === 0 ? Styles.FirstRow : ""}>
			{question?.subquestions &&
				question?.subquestions.map((subQuestion, index) => (
					<MapQuestionTypes
						question={subQuestion}
						key={index}
						index={index}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
						validateForm={validateForm}
					></MapQuestionTypes>
				))}
		</tr>
	);
};

export default MapTableRow;
