import React from "react";
import { SaveUserTest, TestQuestion } from "../../../services";
import { Col, Row } from "react-bootstrap";
import Styles from "./index.module.css";
import MapQuestionTypes from "../MapQuestionTypes";
import { FormikErrors } from "formik";

type Props = {
	question: TestQuestion;
	values: SaveUserTest;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	errors: FormikErrors<SaveUserTest>;
	validateForm: boolean;
};

const MapTopicText = (props: Props) => {
	const { question, setFieldValue, values, errors, validateForm } = props;

	const [displayQuestion, setDisplayQuestion] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (question.showOnAnswerId) {
			setDisplayQuestion(values.scoreQuestions.some(sq => sq.answers.some(x => x.answerId === question.showOnAnswerId) === true));
		} else {
			setDisplayQuestion(true);
		}
	}, [question.showOnAnswerId, values]);

	return (
		<>
			{displayQuestion && (
				<>
					<Col lg={12}>
						<Row className={Styles.QuestionRow}>
							<div className={Styles.QuestionLabel}>
								<div dangerouslySetInnerHTML={{ __html: question.text }} />
							</div>
						</Row>
					</Col>
					{question?.subquestions &&
						question?.subquestions.map((subQuestion, index) => (
							<MapQuestionTypes
								question={subQuestion}
								key={index}
								index={index}
								setFieldValue={setFieldValue}
								values={values}
								errors={errors}
								validateForm={validateForm}
							></MapQuestionTypes>
						))}
				</>
			)}
		</>
	);
};

export default MapTopicText;
