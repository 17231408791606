import React from "react";
import Loading from "../../../components/Loading";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { pageActions as actions } from "../../../store/page";
import { Test, getTestById } from "../../../services";
import Styles from "./index.module.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ContentBox from "../../../components/ContentBox";
import { Col, Row } from "react-bootstrap";
import { BackButton } from "../../../components/BackButton";
import { StatusCircleInnerIcon } from "@fluentui/react-icons-mdl2";
import BasicAppButton from "../../../components/BasicAppButton";
import { BasicModalWithoutCancel } from "../../../components/BasicModalWithoutCancel/Index";
import CreateTest from "../CreateTest";
import CreateTestById from "../CreateTestById";

type Props = {
	updatePageHeaderTitle: (title: string) => void;
};

const TestViewer = (props: Props) => {
	const { updatePageHeaderTitle } = props;

	const { id } = useParams();
	let navigate = useNavigate();
	const [loading, setLoading] = React.useState<boolean>(false);
	//const [test, setTest] = React.useState<TestByIdResponse>();
	const [test, setTest] = React.useState<Test>();
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const [errorMsg, setErrorMsg] = React.useState("");

	React.useEffect(() => {
		updatePageHeaderTitle("Test Mifid");
	}, [updatePageHeaderTitle]);

	React.useEffect(() => {
		setIsError(false);
		setLoading(true);
		if (id != undefined) {
			getTestById(id)
				.then(response => {
					if (response.errorMsg) {
						setIsError(true);
						setErrorMsg(response.errorMsg);
						setShowDeleteModal(true);
					} else {
						setTest(response);
					}
				})
				.finally(() => setLoading(false));
		}
	}, []);

	return (
		<div>
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<BackButton />
			</div>
			{loading && <Loading type="page" />}
			{!loading && test != undefined && test.testIncludedQuestions.length > 0 && (
				<ContentBox headLabel={test.testType.name}>
					<p style={{ color: "grey", fontSize: "12px" }}>
						A continuación podrá ver las respuestas que dió el cliente a las preguntas del último test Mifid que realizó.{" "}
					</p>
					<div>
						<CreateTestById test={test}></CreateTestById>
					</div>
				</ContentBox>
			)}
			{isError && (
				<BasicModalWithoutCancel
					title="Error"
					body={<>{errorMsg}</>}
					btnSubmitText="Aceptar"
					showModal={showDeleteModal}
					onSubmit={() => {
						setLoading(true);
					}}
					onClose={() => {
						setShowDeleteModal(false);
						navigate(-1);
					}}
				></BasicModalWithoutCancel>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
	updatePageHeaderTitle: (title: string) => dispatch(actions.updatePageHeaderTitle(title))
});

export default connect(null, mapDispatchToProps)(TestViewer);
