import axios from "axios";
import config from "../config";

class Auth {
	getUserProfile() {
		var rawProfile = localStorage.getItem("userprofile");
		if (rawProfile != null) {
			return JSON.parse(rawProfile);
		}
		return {};
	}

	getUserEmail() {
		var rawProfile = localStorage.getItem("userprofile");
		let email = localStorage.getItem("email");
		if (rawProfile != null) {
			return JSON.parse(rawProfile).email;
		} else if (email) {
			return email;
		}
		return "";
	}

	async userLogin() {
		let user: any = this.getUserProfile();
		let email = this.getUserEmail();
		if (user === null || user.oid === undefined || !email) {
			var response = await axios.get(`${config.server_host}/user-fid-customers`, { withCredentials: true });
			var jsonData = response.data;
			if (typeof jsonData.oid === "undefined") {
				return false;
			} else {
				this.setUserProfile(jsonData);
				return true;
			}
		} else {
			return true;
		}
	}

	async logout() {
		await fetch(`${config.server_host}/oauth/revoke-fid-customers`, {
			method: "get",
			credentials: "include"
		}).finally(() => {
			localStorage.clear();
			sessionStorage.clear();
			window.location.href = `${config.server_host}/login-fid-customers`;
		});

		// window.location.href = `${config.server_host}/login-fid-customers`;
	}

	setUserProfile(authResponse: any) {
		var userProfile = this.getUserProfile();
		//credentials, token y refresj token
		var fullName: string[] = [];
		var initials: string = "";
		if (authResponse.mainUserIdentity !== undefined && authResponse.mainUserIdentity != null) {
			const fields = ["firstName", "lastName", "secondLastName"];

			for (var fieldIndex in fields) {
				var field = fields[fieldIndex];

				if (authResponse.mainUserIdentity[field] !== undefined && authResponse.mainUserIdentity[field] !== null) {
					fullName.push(`${authResponse.mainUserIdentity[field]}`);
					var fullNameWords = authResponse.mainUserIdentity[field].split(" ");
					if (fullNameWords.length > 0) {
						for (var wordIndex in fullNameWords) {
							if (initials.length < 3 && fullNameWords[wordIndex].length > 0) {
								initials += fullNameWords[wordIndex][0].toUpperCase();
							}
						}
					} else {
						initials = "--";
					}
				}
			}
		} else {
			if (fullName.length === 0) {
				fullName = [userProfile.email];
				initials = "@";
			}
		}

		authResponse.fullName = fullName.join(" ");
		authResponse.initials = initials;

		localStorage.setItem(
			"userprofile",
			JSON.stringify({
				...userProfile,
				...Object.fromEntries(Object.entries(authResponse).filter(([_, v]) => v != null))
			})
		);
		localStorage.setItem("email", userProfile.email);
	}
}

const authClient = new Auth();

export default authClient;
