import { Form } from "react-bootstrap";
import styles from "./index.module.css";

type Props = any;

export const CheckBox = (props: Props) => {
	const { label, name, checked, onChange, disabled, isInvalid, ...rest } = props;

	return (
		<Form.Check required type="checkbox" className={styles.formCheckBox} id={name}>
			<Form.Check.Input name={name} checked={checked} onChange={onChange} disabled={disabled} {...rest} isInvalid={isInvalid} />
			<Form.Check.Label>{label}</Form.Check.Label>
			{/* <Form.Control.Feedback type="invalid">requerido</Form.Control.Feedback> */}
		</Form.Check>
	);
};

export default CheckBox;
