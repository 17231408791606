import React from "react";
import { SaveUserTest, TestQuestion } from "../../../services";
import Styles from "./index.module.css";
import MapQuestionTypes from "../MapQuestionTypes";
import { FormikErrors } from "formik";

type Props = {
	question: TestQuestion;
	values: SaveUserTest;
	errors: FormikErrors<SaveUserTest>;
	validateForm: boolean;
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const MapTableColumn = (props: Props) => {
	const { question, setFieldValue, values, errors, validateForm } = props;

	return (
		<td>
			{question.text && <div>{question.text}</div>}
			{question?.subquestions &&
				question?.subquestions.map((subQuestion, index) => (
					<MapQuestionTypes
						question={subQuestion}
						key={index}
						index={index}
						setFieldValue={setFieldValue}
						values={values}
						errors={errors}
						validateForm={validateForm}
					></MapQuestionTypes>
				))}
		</td>
	);
};

export default MapTableColumn;
