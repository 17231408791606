import { Form } from "react-bootstrap";

type Props = any;

export const RadioButtonField = (props: Props) => {
	const {
		label,
		disabled,
		checked,
		onclick,
		onChange,
		field: { name, value, onChange: FieldOnChange }
	} = props;

	const changeHandler = (e: any) => {
		FieldOnChange(e);
		if (onChange) onChange(e);
	};

	return (
		<Form.Check
			inline
			id={`${name}-${value}`}
			label={label}
			name={name}
			type="radio"
			onChange={changeHandler}
			value={value}
			checked={checked}
			disabled={disabled}
			onClick={onclick}
			style={{ marginLeft: "24px" }}
		/>
	);
};

export default RadioButtonField;
